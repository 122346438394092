<template>
  <v-app>
    <div class="background">
      <HeaderDefault id="home" />
      <MainDefault />
      <FooterLinks />
    </div>
  </v-app>
</template>

<script>
import { HeaderDefault, MainDefault, FooterLinks } from "@/components/layouts"

export default {
  name: "App",
  components: {
    HeaderDefault,
    MainDefault,
    FooterLinks,
  },
}
</script>
